export const detailsTab = {
  order: 3,
  name: "Details tab",
  subcategory: "Navigating Tower Hunt",
  markdown: `# Details tab

  Located within the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}, the Details tab helps you create and research {% inlineRouterLink %}datapoints{% /inlineRouterLink %} that are linked to whatever is selected in a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}, {% inlineRouterLink %}company page{% /inlineRouterLink %}, or {% inlineRouterLink %}contact page{% /inlineRouterLink %}.

  ![Details tab screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Organization

  The Details tab uses vertical sections to organize information. The vertical sections change depending upon what is selected.

  ## Sections

  The table below outlines the available vertical sections.

  | **Name** | **Icon** | **Datapoints** | **Found in** |
  |---|---|---|---|
  | Highlight | {% inlineAppIcon iconName="flag" /%} | News card | Company involvement |
  | {% inlineRouterLink articleId="understand-the-capital-stack" %}Capital stack{% /inlineRouterLink %} | {% inlineAppIcon iconName="capitalStack" /%} | Ownership interest, loan, company, contact, involvement role, equity type  | {% inlineRouterLink articleId="add-a-fee-parcel" %}Fee parcel{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-a-leasehold" %}leasehold{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-an-ownership-unit" %}condominium{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-an-ownership-unit" %}co-op{% /inlineRouterLink %} |
  | Appearance | {% inlineAppIcon iconName="idCard" /%} | {% inlineRouterLink articleId="add-a-name" %}Name{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-an-internal-name" %}internal name{% /inlineRouterLink %} | All except company involvement |
  | Contact info | {% inlineAppIcon iconName="phone" /%} | {% inlineRouterLink articleId="add-contact-info" %}Contact info{% /inlineRouterLink %} | Contact |
  | Location | {% inlineAppIcon iconName="globe" /%} | {% inlineRouterLink articleId="add-a-location" %}Location{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-land-parcel-boundaries" %}parcel boundaries{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-fee-parcel" %}Fee parcel{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %}, {% inlineRouterLink articleId="create-a-property" %}property{% /inlineRouterLink %} |
  | Physical | {% inlineAppIcon iconName="arrowsOut" /%} | {% inlineRouterLink articleId="add-a-building-height" %}Height{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-land-covering" %}Building{% /inlineRouterLink %} |
  | Height | {% inlineAppIcon iconName="arrowsOut" /%} | {% inlineRouterLink articleId="add-building-level-slab-to-slab-height" %}Slab-to-slab height{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-building-levels" %}Building level{% /inlineRouterLink %} |
  | Size | {% inlineAppIcon iconName="arrowsOut" /%} | {% inlineRouterLink articleId="add-land-area" %}Land area{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-fee-parcel" %}Fee parcel{% /inlineRouterLink %} |
  | Sizes | {% inlineAppIcon iconName="arrowsOut" /%} | {% inlineRouterLink articleId="add-floor-area-measures" %}Standardized area, unit-based area{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-floor-area" %}Floor area{% /inlineRouterLink %} |
  | Uses | {% inlineAppIcon iconName="arrowsOut" /%} | {% inlineRouterLink articleId="add-property-uses" %}Property use{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-floor-area" %}Floor area{% /inlineRouterLink %} |
  | Timing | {% inlineAppIcon iconName="clock" /%} | {% inlineRouterLink articleId="add-a-milestone-date" %}Milestone date{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-fee-parcel" %}Fee parcel{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-a-leasehold" %}leasehold{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-an-ownership-unit" %}condominium{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-an-ownership-unit" %}co-op{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-property-enhancement" %}enhancement{% /inlineRouterLink %}, {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %}, advisor involvement |
  | Rooms | {% inlineAppIcon iconName="cube" /%} | {% inlineRouterLink articleId="add-rooms-to-floor-area-layouts" %}Room{% /inlineRouterLink %} | {% inlineRouterLink articleId="add-a-floor-area-layout" %}Floor area layout{% /inlineRouterLink %} |
  | Files | {% inlineAppIcon iconName="folder" /%} | {% inlineRouterLink articleId="add-files-to-datapoints" %}File, folder, web link{% /inlineRouterLink %} | All except building level, same-width building level group, and company involvement |
  | Notes | {% inlineAppIcon iconName="documentText" /%} | {% inlineRouterLink articleId="add-notes-to-datapoints" %}Note{% /inlineRouterLink %} | All |
  | Photos | {% inlineAppIcon iconName="photo" /%} | {% inlineRouterLink articleId="add-files-to-datapoints" %}Image{% /inlineRouterLink %} | All except building level, same-width building level group, floor area, and company involvement |
  `,
};
